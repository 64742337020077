import Big from 'big.js';

import { IExchangeRates, IWallet } from 'types';
import { amount, getImgSrc } from 'func/common';
import { ETokenCode } from 'components/constants/constants';

import { IWalletOption } from './types';

export const walletsToUsd = (wallets: IWallet[], exchangeRates: IExchangeRates) => {
  return wallets.reduce<string>((acc, w) => {
    const hasBalance = Big(w.availableBalance).gt(0);

    if (!hasBalance) return acc;

    const rate = exchangeRates[w.token.tokenCode];

    if (!rate) return acc;

    const amount = Big(w.availableBalance).mul(rate).toFixed();
    const newAcc = Big(acc).plus(amount).toFixed();

    return newAcc;
  }, '0');
};

export const prepareWalletOptions = (wallets: IWallet[]): IWalletOption[] => {
  return wallets.map<IWalletOption>((w) => ({
    value: w.token.tokenCode,
    label: w.token.displayName,
    sublable: w.token.name,
    amount: String(amount(w.availableBalance)),
    image: getImgSrc(w.token.tokenCode),
    selectable: w.token.tokenCode !== ETokenCode.USD,
  }));
};

export const selectedWalletTitle = (
  slotsRun: boolean,
  currentTokenCode: string,
  selectedTokenCode: string,
  balance: string | number,
): string => {
  if (slotsRun) {
    return 'In-Game';
  }

  return String(amount(currentTokenCode === selectedTokenCode ? balance : '0'));
};

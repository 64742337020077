import { useEffect } from 'react';

import { isSafariBrowser } from './utils';

/*
 * The layer is intended to fix a known bug with double click for the safari browser:
 * https://stackoverflow.com/questions/3038898/ipad-iphone-hover-problem-causes-the-user-to-double-click-a-link/
 */
const SafariLayer: React.FC<React.PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    const safari = isSafariBrowser();

    if (!safari) return;

    // Add event listeners for touch and mouse events
    document.addEventListener('touchend', handlePreventDefaultBehaviour, true);
    document.addEventListener('mouseover', handlePreventDefaultBehaviour, true);
    document.addEventListener('mousemove', handlePreventDefaultBehaviour, true);
    document.addEventListener('click', handlePreventDefaultBehaviour, true);

    return () => {
      document.removeEventListener('touchend', handlePreventDefaultBehaviour, true);
      document.removeEventListener('mouseover', handlePreventDefaultBehaviour, true);
      document.removeEventListener('mousemove', handlePreventDefaultBehaviour, true);
      document.removeEventListener('click', handlePreventDefaultBehaviour, true);
    };
  }, []);

  // Tags of interest: Only process certain interactive elements
  const handlePreventClick = (target: HTMLElement) => {
    const tagName = target.tagName.toLowerCase();
    const datasetBind = target.dataset.bind;
    const preventFilter = datasetBind?.includes('click') || tagName === 'a' || tagName === 'button';

    return preventFilter;
  };

  const handleClick = (ev: Event, target: HTMLElement) => {
    // Prevent double click
    if (target.dataset._clicked_) {
      // This check prevents interference with valid tracking
      // and programmatic click events.
      if (ev.isTrusted) {
        ev.preventDefault();
      }

      return;
    }

    // Prevent timeout click
    if (target.dataset._timeout_) {
      const id = Number(target.dataset._timeout_);

      clearTimeout(id);
    }

    // Mark element as clicked
    target.dataset._clicked_ = String(true);
  };

  const handleMouseMove = (ev: Event, target: HTMLElement) => {
    // Prevent default hover behavior
    ev.preventDefault();

    const count = Number(target.dataset._mousemove_ || '0') + 1;

    target.dataset._mousemove_ = String(count);

    // Trigger click event after enough movement
    if (count > 1 && !target.dataset._timeout_) {
      const id = setTimeout(function () {
        // Simulate a click after mouse move
        target.click();
      }, 80); // Adjust delay to fine-tune the click event timing

      target.dataset._timeout_ = String(id);
    }
  };

  const handleMouseOver = (ev: Event) => {
    // Prevent default hover behavior
    ev.preventDefault();
  };

  // Reset any flags on touchend
  const handleTouched = (target: HTMLElement) => {
    target.dataset._clicked_ = '';
    target.dataset._mousemove_ = '0';
    target.dataset._timeout_ = '';
  };

  function handlePreventDefaultBehaviour(ev: Event) {
    const target = ev.target as HTMLElement;

    if (!target) return;

    // Skip elements that don't have click handlers or necessary attributes
    if (!(target.click && target.tagName && target.dataset)) return;
    if (!handlePreventClick(target)) return;

    switch (ev.type) {
      case 'touchend': {
        return handleTouched(target);
      }
      case 'mouseover': {
        return handleMouseOver(ev);
      }
      case 'mousemove': {
        return handleMouseMove(ev, target);
      }
      case 'click': {
        return handleClick(ev, target);
      }
    }
  }

  return <>{children}</>;
};

export default SafariLayer;

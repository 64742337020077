import clsx from 'clsx';
import Big from 'big.js';
import { pathOr } from 'ramda';
import { useContext, useEffect, useRef, useState } from 'react';
import { useQuery, useReactiveVar } from '@apollo/client';

import { useAppSelector } from 'hooks/useAppSelector';
import { generalDataExchangeRates } from 'store/generalData/generalDtata.selectors';
import { GET_ALL_WALLETS } from 'graphQl/query/wallet/wallet';
import { IWallet, SocketActions } from 'types';
import { ContextTokenCode, ContextWallet } from 'context';
import { settingVar } from 'cache';
import SocketContext from 'context/contextSocket/context';
import { ISocketBalanceUpdate } from 'context/contextSocket/types';
import { formatUsdAmount, getImgSrc } from 'func/common';

import ToggleMFA from 'components/Pages/SecurityPage/components/UserSecurity/components/ChangeMFA/components/ToggleMFA';

import styles from './styles.module.scss';
import { prepareWalletOptions, walletsToUsd, selectedWalletTitle } from './utils';
import { IWalletOption } from './types';
import { HIDE_ZERO_BALANCES_KEY } from './constants';

const WalletDropdown: React.FC = () => {
  const { walletUser } = useContext(ContextWallet);
  const { tokenCode, setTokenCode } = useContext(ContextTokenCode);
  const { socket, connected: socketConnected } = useContext(SocketContext);

  const wrapper = useRef<HTMLDivElement | null>(null);

  const [open, setOpen] = useState(false);
  const [hideZero, setHideZero] = useState(false);
  const [usdAmount, setUsdAmount] = useState('0.00');
  const [wallets, setWallets] = useState<IWallet[]>([]);
  const [selectedOption, setSelectedOption] = useState<IWalletOption>();
  const [options, setOptions] = useState<IWalletOption[]>([]);

  const { data } = useQuery(GET_ALL_WALLETS, { fetchPolicy: 'cache-only' });

  const setting = useReactiveVar(settingVar);
  const exchangeRates = useAppSelector(generalDataExchangeRates);

  useEffect(() => {
    const hideZeroBalances = localStorage.getItem(HIDE_ZERO_BALANCES_KEY);

    if (!hideZeroBalances) return;

    const newHideZero = Boolean(hideZeroBalances);

    setHideZero(newHideZero);
  }, []);

  useEffect(() => {
    if (data) {
      const newWallets = pathOr<IWallet[]>([], ['wallets'], data);

      setWallets(newWallets);
    }
  }, [data]);

  useEffect(() => {
    if (!wallets.length) {
      setSelectedOption(undefined);
      setOptions([]);
      return;
    }

    const filteredWallets = !hideZero ? wallets : wallets.filter((w) => Big(w.availableBalance).gt(0));
    const newOptions = prepareWalletOptions(filteredWallets);
    const sortedOptions = [...newOptions].sort((w) => (w.value === tokenCode.token ? -1 : 1));

    const [newOption] = sortedOptions;

    setSelectedOption(newOption);
    setOptions(sortedOptions);
  }, [wallets, open, exchangeRates, tokenCode, hideZero]);

  useEffect(() => {
    // skip calc and state update if dropdown is hidden
    if (!open) return;

    const newUsdAmount = walletsToUsd(wallets, exchangeRates);

    setUsdAmount(newUsdAmount);
  }, [wallets, exchangeRates, open]);

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);
    document.addEventListener('touchstart', handleBlur);

    return () => {
      document.removeEventListener('mousedown', handleBlur);
      document.removeEventListener('touchstart', handleBlur);
    };
  }, []);

  function handleBlur(ev: Event) {
    const clickTarget = ev.target as HTMLElement;

    if (!clickTarget) return;

    const clickOutside = wrapper.current && !wrapper.current.contains(clickTarget);

    if (clickOutside) {
      setOpen(false);
    }
  }

  const handleSocketBalanceUpdate = (data: ISocketBalanceUpdate) => {
    const balance = Object.entries<string>(data);

    setWallets((w) => {
      const newWallets = w.map((w) => {
        const updatedWallet = balance.find(([bTokenCode]) => w.token.tokenCode === bTokenCode);

        return updatedWallet ? { ...w, availableBalance: updatedWallet[1] } : w;
      });

      return newWallets;
    });
  };

  useEffect(() => {
    if (socketConnected) {
      socket?.on(SocketActions.balance, (data) => handleSocketBalanceUpdate(data));
    }
  }, [socketConnected]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleSelect = (o: IWalletOption) => {
    const { value: newTokenCode, label: newDisplayName } = o;

    localStorage.setItem('tokenCode', newTokenCode);
    localStorage.setItem('displayName', newDisplayName);

    setTokenCode({ token: newTokenCode, name: newDisplayName });
    setSelectedOption(o);

    setOpen(false);
  };

  const handleHideZero = (h: boolean) => {
    localStorage.setItem(HIDE_ZERO_BALANCES_KEY, String(h));
    setHideZero(h);
  };

  return (
    <div ref={wrapper} className={styles.wrapper}>
      {selectedOption ? (
        <div tabIndex={0} className={clsx(styles.header, open ? styles.open : '')} onClick={handleOpen}>
          <img className={clsx(styles.icon, styles.selected)} src={selectedOption.image} alt={selectedOption.label} />
          <p className={clsx(styles.text, styles.title)}>
            {selectedWalletTitle(setting.isSlotsRun, selectedOption.value, tokenCode.token, walletUser)}
          </p>
        </div>
      ) : null}
      {open ? (
        <div className={clsx(styles.currenciesWrapper, open ? styles.open : '')}>
          <ul className={clsx(styles.currencies)}>
            {options.map((o, i) => (
              <li
                key={o.value}
                tabIndex={i + 10000}
                className={clsx(styles.currency, o.selectable ? styles.selectable : '')}
                onClick={() => (o.selectable ? handleSelect(o) : null)}
              >
                <div className={styles.row}>
                  <img className={styles.icon} src={o.image} alt={o.label} />
                  <div className={styles.col}>
                    <p className={styles.text}>{o.label}</p>
                    <span className={clsx(styles.text, styles.gray)}>{o.sublable}</span>
                  </div>
                </div>
                <p className={clsx(styles.text, styles.amount)}>{o.amount}</p>
              </li>
            ))}
          </ul>
          <div className={styles.totalWrapper}>
            <div className={styles.totalRow}>
              <span className={styles.totalText}>Total</span>
              <div className={styles.totalAmountWrapper}>
                <img className={styles.usdIcon} src={getImgSrc('USD')} alt="USD Icon" />
                <span className={styles.totalAmount}>{formatUsdAmount(usdAmount, true)} USD</span>
              </div>
            </div>
            <div className={styles.totalRow}>
              <span className={styles.totalText}>Hide Zero Balances</span>
              <ToggleMFA value={hideZero} onChange={handleHideZero} />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default WalletDropdown;

import Big from 'big.js';
import { ETokenCode } from 'components/constants/constants';
import { IExchangeRates, IUser } from 'types';

interface SelectedCurrency {
  token: ETokenCode;
}

export const calcAvailableForWithdrawal = (
  user: IUser,
  exchangeRates: IExchangeRates,
  selectCurrency: SelectedCurrency,
  balance: string,
): string => {
  try {
    const { totalWager } = user;

    const btcRate = exchangeRates[ETokenCode.BTC];
    const currencyRate = exchangeRates[selectCurrency.token];

    const usdTotalWager = Big(totalWager).mul(btcRate).toFixed();
    const currencyAvailableWithdrawal = Big(usdTotalWager).div(currencyRate).toFixed();

    const availableMoreThanBalance = Big(currencyAvailableWithdrawal).gt(balance);

    return availableMoreThanBalance ? balance : currencyAvailableWithdrawal;
  } catch (err) {
    console.log('[ERROR_WHILE_CALC_AVAILABLE_BALANCE]: ', err);

    return '0';
  }
};

import { IToggleMFA } from './types';
import styles from './styles.module.scss';

const ToggleMFA: React.FC<IToggleMFA> = ({ value, onChange }) => {
  return (
    <div className={styles.toggleMfa}>
      <label className={styles.toggleMfaSwitch}>
        <input type="checkbox" checked={value} onChange={(ev) => onChange(ev.target.checked)} />
        <span className={styles.toggleMfaSlider} />
      </label>
    </div>
  );
};

export default ToggleMFA;

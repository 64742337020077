import React, { useContext, useEffect } from 'react';

import walletIcon from 'assets/img/header/wallet-color-icon.webp';

import { ContextPopUps } from 'context';
import { popUps } from 'components/constants/constants';
import { useLocalization } from 'components/Internationalization';

import WalletDropdown from 'components/Header/components/HeaderAuth/components/WalletDropdown';

import styles from './styles.module.scss';

const Wallet: React.FC = () => {
  const { translate } = useLocalization();
  const { popUpsOpen, setPopUpsOpen } = useContext(ContextPopUps);

  const handleOpenPopUps = () =>
    setPopUpsOpen({
      modalOpen: popUps.walletNavigation,
      data: { config: popUps.deposit, active: popUps.deposit },
    });

  useEffect(() => {
    document.body.style.setProperty('overflow', popUpsOpen ? 'hidden' : 'unset');
  }, [popUpsOpen]);

  return (
    <div className={styles.wallet}>
      <div className={styles.walletCurrency}>
        <WalletDropdown />
      </div>
      <div className={styles.walletButton} onClick={handleOpenPopUps}>
        <img src={walletIcon} alt="wallet" className={styles.walletIcon} data-tip data-for="registerTip" />
        <p>{translate('header.wallet')}</p>
      </div>
      <div className={styles.walletMob}>
        <img onClick={handleOpenPopUps} src={walletIcon} alt="wallet" />
      </div>
    </div>
  );
};

export default React.memo(Wallet);
